import React, { useState } from 'react';
import {
    Box,
    Typography,
    Container,
    Grid,
    Card,
    CardContent,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../layout/footer_bar/footer_bar_main';

const DSATInfo = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: ''
    });

    const courseInfo = {
        overview: `The DSAT (Direct Scholastic Aptitude Test) is a standardized college admission test designed to assess a student's readiness for college. It evaluates skills in critical reading, writing, and mathematics.`,
        importance: `Performing well on the DSAT can significantly impact college admissions, providing schools with a standardized measure of academic potential.`,
        testFormat: [
            { title: "Critical Reading", details: "40 questions, focusing on reading comprehension and analytical skills." },
            { title: "Mathematics", details: "40 questions, covering algebra, geometry, and data analysis." },
            { title: "Writing", details: "40 questions, assessing grammar, punctuation, and sentence structure." },
        ],
        preparationTips: [
            "Review the test format and types of questions.",
            "Use practice tests to familiarize yourself with the content.",
            "Focus on areas where you feel less confident.",
            "Consider joining study groups or tutoring sessions."
        ],
        faqs: [
            {
                question: "How long is the DSAT?",
                answer: "The DSAT is approximately 3 hours long, including breaks."
            },
            {
                question: "What scores do colleges look for?",
                answer: "Scores vary by institution; however, a score above 1000 is generally competitive."
            },
            {
                question: "Can I retake the DSAT?",
                answer: "Yes, students can retake the DSAT to improve their scores."
            }
        ]
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Submitted data:', formData);
        // Add your submission logic here
        handleDialogClose();
    };

    return (
        // <Box sx={{ backgroundColor: '#f5f5f5' }}>
        <Box sx={{marginTop:"5rem"}}>
            <Container maxWidth="lg">
                <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#003366' }}>
                    DSAT (Direct Scholastic Aptitude Test)
                </Typography>

                <Typography variant="body1" paragraph>
                    {courseInfo.overview}
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Importance of DSAT
                </Typography>
                <Typography variant="body1" paragraph>
                    {courseInfo.importance}
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Test Format
                </Typography>
                <Grid container spacing={2}>
                    {courseInfo.testFormat.map((format, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h6">{format.title}</Typography>
                                    <Typography variant="body2">{format.details}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
                    Preparation Tips
                </Typography>
                <ul>
                    {courseInfo.preparationTips.map((tip, index) => (
                        <li key={index}>
                            <Typography variant="body1">{tip}</Typography>
                        </li>
                    ))}
                </ul>

                <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
                    Frequently Asked Questions
                </Typography>
                {courseInfo.faqs.map((faq, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                            <Typography>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

                <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Button
  variant="contained"
  onClick={handleDialogOpen}
  sx={{
    mr: 2,
    backgroundImage: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',  // Applying the gradient as backgroundImage
    color: 'white',  // Ensure the text is visible over the gradient
    '&:hover': {
      backgroundImage: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)', // Optional: Change gradient on hover
    },
  }}
>
  Apply Now
</Button>

                    <Button variant="outlined" color="secondary" href="/contact">
                        Contact Us
                    </Button>
                </Box>
            </Container>

            {/* Dialog for Apply Now */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Application Form</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Full Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Email"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Phone Number"
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                        Submit Application
                    </Button>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <FooterBar/>
        </Box>
    );
};

export default DSATInfo;
