import React from 'react';
import { Box, Typography, Container, Card, CardContent } from '@mui/material';
import Slider from "react-slick";
import { Link } from 'react-router-dom'; 
import Message from '../section/message.jsx';
import OurServices from '../section/services';
import Testimonial from '../section/Testimonial';
import Information from '../section/newsNotices';
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import Videos from '../section/Videos';

import BookIcon from '@mui/icons-material/Book';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import QuizIcon from '@mui/icons-material/Quiz';  // Icon for mock tests
import MenuBookIcon from '@mui/icons-material/MenuBook'; // Icon for study material
import LocationOnIcon from '@mui/icons-material/LocationOn'; // Icon for college visits
import SupportIcon from '@mui/icons-material/Support'; // Icon for counseling
import BachelorPrograms from './bachelorPrograms.jsx';

// Courses data
const courses = [
    {
        id: 0,
        title: 'KUUMAT',
        description: 'Kathmandu University Undergraduate Management Admission Test',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKVJF9FpVhhVlVfqOnFodopRbm4EBbzBn_3Q&s',
        link: '/kuumat'
    },
    {
        id: 1,
        title: 'CMAT',
        description: 'Central Management Admission Test',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6IFgjukLJAswZ0-_M2fL4E4UF8CNCqwtXHg&s',
        link: '/cmat'
    },
    {
        id: 2,
        title: 'BBA-FI',
        description: 'Bachelor of Finance',
        image: 'https://www.shutterstock.com/image-illustration/bba-graduation-bachelor-business-administration-600nw-312491207.jpg',
        link: '/bba-fi'
    }
];

const StudyInNepal = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 600,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        centerPadding: '10px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Box sx={{
            backgroundColor: '#f5f5f5',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            {/* Main Heading */}
            <Container maxWidth="lg">
                <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, 
                        fontWeight: 'bold',
                        color: '#003366',
                        textTransform: 'uppercase', mt: { xs: 4, md: 6 } }}>
                    Study in Nepal
                </Typography>
                <Typography variant="body1" align="center" color="textSecondary" paragraph sx={{ mb: { xs: 4, md: 6 } }}>
                    Discover various academic courses offered in Nepal, helping students achieve their educational goals.
                </Typography>

                {/* Course Slider */}
                <Slider {...settings} style={{ backgroundColor: "transparent" }}>
                    {courses.map((course) => (
                        <div key={course.id}>
                            <Card sx={{
                                height: '300px',
                                borderRadius: '12px',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                                margin: '10px 10px',
                            }}>
                                <Link to={course.link} style={{ textDecoration: 'none', color: 'inherit', height: '100%' }}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img 
                                            src={course.image} 
                                            alt={course.title} 
                                            style={{ 
                                                width: '100%', 
                                                height: '110px', 
                                                objectFit: 'cover', 
                                                borderRadius: '12px', 
                                                marginBottom: '16px' 
                                            }} 
                                        />
                                        
                                        <Typography variant="h6" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                                            {course.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" align="center">
                                            {course.description}
                                        </Typography>
                                    </CardContent>
                                </Link>
                            </Card>
                        </div>
                    ))}
                </Slider>
            </Container>

            {/* Why Choose Us? Section */}
            <section className="second-section">
                <Typography 
                    variant="h4" 
                    className="section-title" 
                    sx={{ 
                        fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, 
                        fontWeight: 'bold',
                        color: '#003366',
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        marginBottom: { xs: '1.5rem', sm: '2rem' }
                    }}>
                    Why Choose Us?
                </Typography>
                
                <Box 
                    className="icon-container" 
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: { xs: 'column', sm: 'row' },
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        width: '100%',
                        maxWidth: '900px',
                    }}
                >
                    {/* Icon Boxes */}
                    <Box className="icon-box" sx={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <BookIcon className="icon" sx={{ fontSize: '3rem', color: '#1F6F8E', marginBottom: '0.5rem' }} />
                        <Typography variant="h6">Student Support</Typography>
                    </Box>
                    
                    <Box className="icon-box" sx={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <PeopleIcon className="icon" sx={{ fontSize: '3rem', color: '#1F6F8E', marginBottom: '0.5rem' }} />
                        <Typography variant="h6">Group Activities</Typography>
                    </Box>

                    <Box className="icon-box" sx={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <SchoolIcon className="icon" sx={{ fontSize: '3rem', color: '#1F6F8E', marginBottom: '0.5rem' }} />
                        <Typography variant="h6">Certified Teachers</Typography>
                    </Box>

                    {/* New Icon Boxes */}
                    <Box className="icon-box" sx={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <QuizIcon className="icon" sx={{ fontSize: '3rem', color: '#1F6F8E', marginBottom: '0.5rem' }} />
                        <Typography variant="h6">Daily/Weekly Mock Tests</Typography>
                    </Box>

                    <Box className="icon-box" sx={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <MenuBookIcon className="icon" sx={{ fontSize: '3rem', color: '#1F6F8E', marginBottom: '0.5rem' }} />
                        <Typography variant="h6">Free Study Material</Typography>
                    </Box>

                    <Box className="icon-box" sx={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <LocationOnIcon className="icon" sx={{ fontSize: '3rem', color: '#1F6F8E', marginBottom: '0.5rem' }} />
                        <Typography variant="h6">College Visits</Typography>
                    </Box>

                    <Box className="icon-box" sx={{ margin: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <SupportIcon className="icon" sx={{ fontSize: '3rem', color: '#1F6F8E', marginBottom: '0.5rem' }} />
                        <Typography variant="h6">Full Counseling</Typography>
                    </Box>
                </Box>
            </section>

            <BachelorPrograms />
            <OurServices />
            <Testimonial />
            <Message />
            <Information />
            <Videos />
            <FooterBar />
        </Box>
    );
};

export default StudyInNepal;
