import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
} from '@mui/material';
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
  LinkedIn as LinkedInIcon,
  ContactMail as ContactMailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationOnIcon,
} from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/educationlogo.png';

// Navigation items for footer
const footerNavItems = [
  { name: "About Us", to: "/about" },
  // { name: "Courses", to: "/courses" },
  // { name: "Entrance Exams", to: "/entrance-exams" },
  // { name: "Privacy Policy", to: "/privacy-policy" },
  { name: "Study in Nepal", to: "/study-in-nepal" },
  { name: "Study Abroad", to: "/study-abroad" },
];

const FooterBar = () => {
  return (
    <Box sx={{ width: '100%', background: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)', color: '#ffffff', marginTop: '4rem', padding: '20px 0' }}>
      <Container>
        <Grid container spacing={4}>
          {/* Logo and Contact Information */}
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
              <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '8px' }} />
              <Typography variant="h6">educ@tion.com</Typography>
            </Box>
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
              <LocationOnIcon sx={{ marginRight: 1 }} />
              Opposite Shankar Dev Campus (SDC), Putalisadak, Kathmandu
            </Typography>
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
              <PhoneIcon sx={{ marginRight: 1 }} />
              01-4168086, 4168106
            </Typography>
            <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
              <ContactMailIcon sx={{ marginRight: 1 }} />
              education.com4@gmail.com
            </Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Typography variant="h6" gutterBottom>Quick Links</Typography>
            {footerNavItems.map((item) => (
              <Typography key={item.to} component={RouterLink} to={item.to} sx={{ textDecoration: 'none', color: '#ffffff', display: 'block', marginBottom: 1 }}>
                {item.name}
              </Typography>
            ))}
          </Grid>

          {/* Social Media */}
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <Typography variant="h6" gutterBottom>Follow us on</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Link href="https://facebook.com" target="_blank" color="inherit" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <FacebookIcon /> <Typography variant="body1" sx={{ ml: 1 }}>Facebook</Typography>
              </Link>
              <Link href="https://instagram.com" target="_blank" color="inherit" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <InstagramIcon /> <Typography variant="body1" sx={{ ml: 1 }}>Instagram</Typography>
              </Link>
              <Link href="https://youtube.com" target="_blank" color="inherit" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <YouTubeIcon /> <Typography variant="body1" sx={{ ml: 1 }}>YouTube</Typography>
              </Link>
              <Link href="https://linkedin.com" target="_blank" color="inherit" sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <LinkedInIcon /> <Typography variant="body1" sx={{ ml: 1 }}>LinkedIn</Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ textAlign: 'center', marginTop: 2, marginBottom: 2 }}>
          <Typography variant="body2">&copy; All rights reserved.</Typography>
          <Typography variant="body2">
            Developed and Designed by <Link href="https://blackmoon.com.np/" target="_blank" color="inherit">Blackmoon</Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default FooterBar;
