import React, { useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { Announcement, Info } from '@mui/icons-material';

// News Data
const news = [
  { id: 1, title: "New Online Course Available", date: "2024-09-01" },
  { id: 2, title: "Upcoming Workshop on AI", date: "2024-09-15" },
];

// Notices Data
const notices = [
  { id: 1, title: "School will be closed on holidays", date: "2024-10-01" },
  { id: 2, title: "Parent-Teacher Meeting", date: "2024-10-05" },
];

function Information() {
  const [showMoreNews, setShowMoreNews] = useState(false);
  const [showMoreNotices, setShowMoreNotices] = useState(false);

  return (
    <Box sx={{ padding: 2, marginTop: '1rem', backgroundColor: '#eaf2f8' }}> 
      <Grid container spacing={9}> 
        
        {/* News Section */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#003366' }}>
            Latest News
          </Typography>
          <Grid container spacing={3}>
            {news.slice(0, showMoreNews ? news.length : 2).map((item) => (
              <Grid item xs={12} key={item.id}>
                <Card variant="outlined" sx={{
                  textAlign: 'center',
                  boxShadow: 3,
                  transition: 'transform 0.3s',
                  height: 200,
                  margin: '0 auto',
                  borderRadius: 2,
                  backgroundColor: '#ffffff',
                  maxWidth: 500, // Set maximum width
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                }}>
                  <CardContent>
                    <Info style={{ fontSize: 40, color: '#003366' }} />
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                      {item.date}: {item.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
            <Grid item>
              <Button onClick={() => setShowMoreNews(!showMoreNews)} sx={{
                backgroundColor: '#ff9800',
                '&:hover': { backgroundColor: '#ff5722' },
                color: 'white',
                marginTop: 1,
                width: '100%',
              }}>
                {showMoreNews ? "View Less" : "View More"}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* Notices Section */}
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#003366' }}>
            Important Notices
          </Typography>
          <Grid container spacing={3}>
            {notices.slice(0, showMoreNotices ? notices.length : 2).map((item) => (
              <Grid item xs={12} key={item.id}>
                <Card variant="outlined" sx={{
                  textAlign: 'center',
                  boxShadow: 3,
                  transition: 'transform 0.3s',
                  height: 200,
                  margin: '0 auto',
                  borderRadius: 2,
                  backgroundColor: '#ffffff',
                  maxWidth: 500, // Set maximum width
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 6,
                  },
                }}>
                  <CardContent>
                    <Announcement style={{ fontSize: 40, color: '#003366' }} />
                    <Typography variant="body1" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                      {item.date}: {item.title}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent="center" sx={{ marginTop: 2 }}>
            <Grid item>
              <Button onClick={() => setShowMoreNotices(!showMoreNotices)} sx={{
                backgroundColor: '#ff9800',
                '&:hover': { backgroundColor: '#ff5722' },
                color: 'white',
                marginTop: 1,
                width: '100%',
              }}>
                {showMoreNotices ? "View Less" : "View More"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Information;
