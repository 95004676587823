import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Container, Grid } from '@mui/material';
import BBAFI from './bbafi';
import CMAT from './CMAT';
import KUUMAT from './KUUMAT';

const courses = {
    0: BBAFI,
    1: CMAT,
    2: KUUMAT
};

function CourseDetail() {
    const { id } = useParams();
    const course = courses[parseInt(id)];

    if (!course) {
        return (
            <Container maxWidth="md" sx={{ py: 4 }}>
                <Typography variant="h5" color="error">
                    Course not found
                </Typography>
            </Container>
        );
    }

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    {course.title}
                </Typography>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={8} md={6}>
                        <img 
                            src={course.image} 
                            alt={course.title} 
                            style={{ 
                                width: '100%', 
                                height: 'auto', 
                                borderRadius: '8px', 
                                marginBottom: '16px' 
                            }} 
                        />
                    </Grid>
                </Grid>
                <Typography variant="h6" component="div" gutterBottom>
                    {course.description}
                </Typography>
                <Typography variant="body1" align="justify" sx={{ lineHeight: 1.6 }}>
                    {course.fullDescription}
                </Typography>
            </Box>
        </Container>
    );
}

export default CourseDetail;
