import React from 'react';
import { Box, Typography } from '@mui/material';
import BookIcon from '@mui/icons-material/Book';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School'; 
import './Icon_Section.style.css'; 

function IconSection() {
  return (
    <section className="second-section">
      <Typography 
        variant="h4" 
        className="section-title" 
        sx={{ 
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, 
          textAlign: 'center',
          marginBottom: { xs: '1.5rem', sm: '2rem' }
        }}>
        Why Choose Us?
      </Typography>
      
      <Box 
        className="icon-container" 
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' }, // Stack icons vertically on small screens, horizontally on larger
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          maxWidth: '900px',
        }}
      >
        <Box 
          className="icon-box"
          sx={{
            margin: { xs: '1rem 0', sm: '1rem' }, // Adjust margins for responsiveness
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <BookIcon 
            className="icon" 
            sx={{ 
              fontSize: { xs: '2.5rem', sm: '3rem' }, 
              color: '#1F6F8E',
              marginBottom: '0.5rem',
            }} 
          />
          <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>Student Support</Typography>
        </Box>

        <Box 
          className="icon-box"
          sx={{
            margin: { xs: '1rem 0', sm: '1rem' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <PeopleIcon 
            className="icon" 
            sx={{ 
              fontSize: { xs: '2.5rem', sm: '3rem' }, 
              color: '#1F6F8E',
              marginBottom: '0.5rem',
            }} 
          />
          <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>Group Activities</Typography>
        </Box>

        <Box 
          className="icon-box"
          sx={{
            margin: { xs: '1rem 0', sm: '1rem' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <SchoolIcon 
            className="icon" 
            sx={{ 
              fontSize: { xs: '2.5rem', sm: '3rem' }, 
              color: '#1F6F8E',
              marginBottom: '0.5rem',
            }} 
          />
          <Typography variant="h6" sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }}>Certified Teachers</Typography>
        </Box>
      </Box>
    </section>
  );
}

export default IconSection;
