import React, { useState } from 'react';
import {
    Box,
    Typography,
    Container,
    Grid,
    Card,
    CardContent,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../layout/footer_bar/footer_bar_main';

const IELTSInfo = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: ''
    });

    const courseInfo = {
        overview: `The IELTS (International English Language Testing System) is a globally recognized test designed to assess the language ability of candidates who need to study or work where English is the primary language of communication.`,
        importance: `The IELTS score is crucial for university admission and visa applications in English-speaking countries, ensuring that students have the language skills necessary to succeed in their studies and daily life.`,
        testFormat: [
            { title: "Listening", details: "30 minutes, includes four recordings of native English speakers." },
            { title: "Reading", details: "60 minutes, three reading passages with various question types." },
            { title: "Writing", details: "60 minutes, two tasks: Task 1 and Task 2 (an essay)." },
            { title: "Speaking", details: "11–14 minutes, a face-to-face interview with an examiner." }
        ],
        preparationTips: [
            "Familiarize yourself with the test format.",
            "Take practice tests to assess your level.",
            "Enroll in preparatory courses if necessary.",
            "Improve your English skills through reading and speaking."
        ],
        faqs: [
            {
                question: "What is a good IELTS score?",
                answer: "A score of 6.5 or higher is generally considered good for university admissions."
            },
            {
                question: "How long is the IELTS score valid?",
                answer: "IELTS scores are valid for two years."
            },
            {
                question: "Can I take the IELTS test online?",
                answer: "Yes, the IELTS test can be taken online in certain regions."
            }
        ]
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Submitted data:', formData);
        // Add your submission logic here
        handleDialogClose();
    };

    return (
        // <Box sx={{ backgroundColor: '#f5f5f5',  }}>
        <Box sx={{marginTop:"5rem"}}>
            <Container maxWidth="lg">
                <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#003366' }}>
                    IELTS (International English Language Testing System)
                </Typography>

                <Typography variant="body1" paragraph>
                    {courseInfo.overview}
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Importance of IELTS
                </Typography>
                <Typography variant="body1" paragraph>
                    {courseInfo.importance}
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Test Format
                </Typography>
                <Grid container spacing={2}>
                    {courseInfo.testFormat.map((format, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card sx={{ height: '100%' }}> {/* Ensures equal height */}
                                <CardContent>
                                    <Typography variant="h6">{format.title}</Typography>
                                    <Typography variant="body2">{format.details}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
                    Preparation Tips
                </Typography>
                <ul>
                    {courseInfo.preparationTips.map((tip, index) => (
                        <li key={index}>
                            <Typography variant="body1">{tip}</Typography>
                        </li>
                    ))}
                </ul>

                <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
                    Frequently Asked Questions
                </Typography>
                {courseInfo.faqs.map((faq, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                            <Typography>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

                <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Button
  variant="contained"
  onClick={handleDialogOpen}
  sx={{
    mr: 2,
    backgroundImage: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',  // Applying the gradient as backgroundImage
    color: 'white',  // Ensure the text is visible over the gradient
    '&:hover': {
      backgroundImage: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)', // Optional: Change gradient on hover
    },
  }}
>
  Apply Now
</Button>

                    <Button variant="outlined" color="secondary" href="/contact">
                        Contact Us
                    </Button>
                </Box>
            </Container>

            {/* Dialog for Apply Now */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Application Form</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Full Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Email"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Phone Number"
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                        Submit Application
                    </Button>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <FooterBar/>
        </Box>
    );
};

export default IELTSInfo;
