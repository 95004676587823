
import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Container, Grid, Card, CardContent, Avatar, Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem, InputLabel, FormControl, CircularProgress } from '@mui/material';
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import generateAlumniData from './alumniData'; 

// Fetch alumni based on selected year and pagination
const fetchAlumni = (year, startIndex, limit) => {
  const alumniData = generateAlumniData(); // Get all alumni data
  const allAlumni = alumniData[year] || [];
  return new Promise((resolve) => {
    setTimeout(() => {
      const alumniSlice = allAlumni.slice(startIndex, startIndex + limit); // Get the next batch of alumni
      resolve(alumniSlice);
    }, 1000); // Simulate a network delay
  });
};

function AlumniStudentWithRank() {
  const [open, setOpen] = useState(false);
  const [selectedAlumni, setSelectedAlumni] = useState(null);
  const [filteredAlumni, setFilteredAlumni] = useState([]);
  const [year, setYear] = useState(2024);
  const [loading, setLoading] = useState(false);
  const [displayCount] = useState(12); // Students per page
  const [currentPage, setCurrentPage] = useState(0); // Current page number

  // Handle alumni filter change (year or display count)
  const handleFilterChange = useCallback(async () => {
    setLoading(true);
    const newAlumni = await fetchAlumni(year, 0, displayCount); // Get the first set of alumni for the selected year
    setFilteredAlumni(newAlumni); 
    setCurrentPage(1); // Start from the next page
    setLoading(false);
  }, [year, displayCount]);

  // Handle card click (open dialog with detailed info)
  const handleCardClick = (alumni) => {
    setSelectedAlumni(alumni);
    setOpen(true);
  };

  // Close the dialog
  const handleClose = () => {
    setOpen(false);
    setSelectedAlumni(null);
  };

  // Load more alumni when "Load More" is clicked
  const loadMoreAlumni = async () => {
    setLoading(true);
    const newAlumni = await fetchAlumni(year, currentPage * displayCount, displayCount); // Get the next batch of alumni
    setFilteredAlumni((prevAlumni) => [...prevAlumni, ...newAlumni]); // Append new alumni to the list
    setCurrentPage(currentPage + 1); // Increment the page number
    setLoading(false);
  };

  // Effect hook to fetch alumni when year or display count changes
  useEffect(() => {
    handleFilterChange();
  }, [handleFilterChange]);  // Add handleFilterChange to the dependencies
//    [year, displayCount]);

  return (
    <Box sx={{ background: 'linear-gradient(135deg, #f0f0f0, #dcdcdc)', minHeight: '100vh', color: 'black' }}>
      <Container maxWidth="lg" sx={{ paddingTop: '4rem', paddingBottom: '3rem' }}>
        <Typography variant="h3" component="h1" gutterBottom align="center" sx={{  fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
          fontWeight: 'bold',
          color: '#003366',
          textTransform: 'uppercase', marginBottom: '1rem' }}>
          Our Distinguished Alumni
        </Typography>

        {/* Year Selector */}
        <FormControl sx={{ minWidth: 120, mb: 3 }}>
          <InputLabel sx={{ color: '#555' }}>Year</InputLabel>
          <Select
            value={year}
            onChange={(e) => setYear(e.target.value)}
            sx={{
              color: '#555',
              background: 'rgba(255, 255, 255, 0.8)',
              '& .MuiInputBase-root': { color: '#555' },
              '& .MuiInputLabel-root': { color: '#555' },
            }}
          >
            {[...Array(16).keys()].map((_, index) => {
              const yearOption = 2024 - index;
              return (
                <MenuItem key={yearOption} value={yearOption}>
                  {yearOption}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {/* Alumni Cards */}
        <Grid container spacing={4}>
          {filteredAlumni.map((alumni) => (
            <Grid item xs={12} sm={6} md={4} key={alumni.rank}>
                <Card sx={{
                background: 'rgba(255, 255, 255, 0.1)',
                borderRadius: 3,
                boxShadow: 3,
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  transform: 'scale(1.05)',
                  transition: 'all 0.3s ease-in-out',
                }
              }} onClick={() => handleCardClick(alumni)}>
                <CardContent>
                  <Avatar src={alumni.image} alt={alumni.name} sx={{ width: 80, height: 80, mx: 'auto', mb: 2, borderRadius: '50%' }} />
                  <Typography variant="h6" sx={{  color:  '333' }}>
                    {alumni.name}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ color: '#555' }}>
                    Rank: {alumni.rank}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>
                    {alumni.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Load More Button */}
        {filteredAlumni.length < generateAlumniData()[year].length && (
          <Box textAlign="center" mt={4}>
            <Button
              variant="contained"
              onClick={loadMoreAlumni}
              sx={{
                // backgroundColor: '#00796b',
                // '&:hover': { backgroundColor: '#004d40' },
                // color: 'white',
                background: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
                padding: '10px 20px',
                fontWeight: 'bold',
              }}
            >
              {loading ? <CircularProgress size={24} color="secondary" /> : 'Load More'}
            </Button>
          </Box>
        )}
      </Container>

      {/* Dialog for displaying full alumni information */}
      <Dialog open={open} onClose={handleClose}>
        {selectedAlumni && (
          <>
            <DialogTitle sx={{ fontWeight: 'bold', color: '#333' }}>{selectedAlumni.name}</DialogTitle>
            <DialogContent>
              <Box display="flex" flexDirection="column" alignItems="center">
                <Avatar src={selectedAlumni.image} alt={selectedAlumni.name} sx={{ width: 120, height: 120, mb: 2 }} />
                <Typography variant="h6" sx={{ color: '#00796b' }}>Rank: {selectedAlumni.rank}</Typography>
                <Typography variant="body1" sx={{ mt: 2, color: '#555' }}>{selectedAlumni.description}</Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" sx={{ fontWeight: 'bold' }}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <FooterBar />
    </Box>
  );
}

export default AlumniStudentWithRank;
