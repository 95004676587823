import React, { useState } from 'react';
import { Box, Typography, Container, Card, CardContent, Button, Accordion, AccordionSummary, AccordionDetails, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../layout/footer_bar/footer_bar_main';

const CMATDetail = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        program: 'CMAT', // Default program
    });

    const cmatInfo = {
        overview: `The Common Management Admission Test (CMAT) is an entrance exam conducted for admission to various management programs in Nepal. It assesses candidates' knowledge and aptitude in various subjects relevant to management studies.`,
        eligibility: [
            "Must have completed a minimum of 10+2 or equivalent.",
            "Students with a bachelor's degree can also apply.",
            "Candidates must meet the specific eligibility criteria set by the respective institutions."
        ],
        examPattern: {
            duration: "2 hours",
            totalQuestions: 100,
            markingScheme: "1 mark for each correct answer; 0.25 marks deducted for each wrong answer.",
            sections: [
                "Quantitative Ability",
                "Verbal Ability and Reading Comprehension",
                "Logical Reasoning",
                "General Awareness"
            ]
        },
        syllabus: [
            {
                title: "Quantitative Ability",
                topics: ["Number System", "Algebra", "Geometry", "Data Interpretation"]
            },
            {
                title: "Verbal Ability",
                topics: ["Reading Comprehension", "Grammar", "Vocabulary", "Sentence Correction"]
            },
            {
                title: "Logical Reasoning",
                topics: ["Logical Deduction", "Critical Reasoning", "Analytical Reasoning"]
            },
            {
                title: "General Awareness",
                topics: ["Current Affairs", "Business and Economy", "Static General Knowledge"]
            }
        ],
        preparationTips: [
            "Practice previous years' question papers.",
            "Take mock tests to build speed and accuracy.",
            "Focus on weaker areas while revising.",
            "Stay updated with current affairs and general knowledge."
        ],
        faqs: [
            {
                question: "What is the CMAT exam fee?",
                answer: "The CMAT exam fee is NPR 3000, which is non-refundable."
            },
            {
                question: "How can I apply for the CMAT?",
                answer: "Candidates can apply online through the official CMAT website or visit designated centers."
            },
            {
                question: "What are the best resources for CMAT preparation?",
                answer: "Recommended resources include CMAT preparation books, online courses, and coaching centers."
            }
        ]
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Submitted data:', formData);
        // Add your submission logic here
        handleDialogClose();
    };

    return (
        // <Box sx={{ backgroundColor: '#f5f5f5' }}>
        <Box sx={{marginTop:"3rem"}}>
            <Container maxWidth="lg">
                <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#003366' }}>
                    CMAT (Common Management Admission Test)
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    Overview
                </Typography>
                <Typography variant="body1" paragraph>
                    {cmatInfo.overview}
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    Eligibility
                </Typography>
                <ul>
                    {cmatInfo.eligibility.map((item, index) => (
                        <li key={index}>
                            <Typography variant="body1">{item}</Typography>
                        </li>
                    ))}
                </ul>

                <Typography variant="h6" component="h2" gutterBottom>
                    Exam Pattern
                </Typography>
                <Card sx={{ marginBottom: 2 }}>
                    <CardContent>
                        <Typography variant="body1"><strong>Duration:</strong> {cmatInfo.examPattern.duration}</Typography>
                        <Typography variant="body1"><strong>Total Questions:</strong> {cmatInfo.examPattern.totalQuestions}</Typography>
                        <Typography variant="body1"><strong>Marking Scheme:</strong> {cmatInfo.examPattern.markingScheme}</Typography>
                        <Typography variant="body1"><strong>Sections:</strong> {cmatInfo.examPattern.sections.join(', ')}</Typography>
                    </CardContent>
                </Card>

                <Typography variant="h6" component="h2" gutterBottom>
                    Syllabus
                </Typography>
                {cmatInfo.syllabus.map((subject, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
                            <Typography>{subject.title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul>
                                {subject.topics.map((topic, idx) => (
                                    <li key={idx}>
                                        <Typography variant="body2">{topic}</Typography>
                                    </li>
                                ))}
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                ))}

                <Typography variant="h6" component="h2" gutterBottom>
                    Preparation Tips
                </Typography>
                <ul>
                    {cmatInfo.preparationTips.map((tip, index) => (
                        <li key={index}>
                            <Typography variant="body1">{tip}</Typography>
                        </li>
                    ))}
                </ul>

                <Typography variant="h6" component="h2" gutterBottom>
                    Frequently Asked Questions
                </Typography>
                {cmatInfo.faqs.map((faq, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}b-content`} id={`panel${index}b-header`}>
                            <Typography>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

                <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Button
  variant="contained"
  onClick={handleDialogOpen}
  sx={{
    mr: 2,
    backgroundImage: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',  // Applying the gradient as backgroundImage
    color: 'white',  // Ensure the text is visible over the gradient
    '&:hover': {
      backgroundImage: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)', // Optional: Change gradient on hover
    },
  }}
>
  Apply Now
</Button>

                    <Button variant="outlined" color="secondary" href="/contact">
                        Contact Us
                    </Button>
                </Box>
            </Container>

            {/* Dialog for Apply Now */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Application Form</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Full Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Email"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Phone Number"
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        sx={{ mr:'19rem' }}
                    >
                        Submit Application
                    </Button>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <FooterBar />
        </Box>
    );
};

export default CMATDetail;
