import React, { useState } from 'react';
import { Card, CardContent, Typography, Avatar, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import './Testimonial.css';

const testimonialsData = [
  {
    name: "Bob Johnson",
    text: "A fantastic experience from start to finish.",
    position: "IOE 2024 Rank 2, KMC, Kathmandu",
    image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSSAv_E5vfoOuY6bdFJ3LEDuDCK7PrWznpPuw&s"
  },
  {
    name: "Carol Williams",
    text: "The team is incredibly professional and friendly.",
    position: "IOE 2024 Rank 2, KMC, Kathmandu",
    image: "https://img.freepik.com/premium-photo/young-student-girl-smiling-against-university-cute-girl-student-holds-folders-notebooks-hands-learning-education-concept_101969-2404.jpg"
  },
  {
    name: "Alice Smith",
    text: "An enlightening experience that broadened my horizons.",
    position: "IOE 2024 Rank 5, KMC, Kathmandu",
    image: "https://cdn.pixabay.com/photo/2018/06/27/07/45/college-student-3500990_1280.jpg"
  },
  {
    name: "John Doe",
    text: "Invaluable guidance and support throughout my journey.",
    position: "IOE 2024 Rank 3, KMC, Kathmandu",
    image: "https://img.freepik.com/free-photo/young-smiling-student-woman-white-background_231208-2062.jpg?semt=ais_hybrid"
  },
  {
    name: "Jane Doe",
    text: "I couldn't have asked for a better experience!",
    position: "IOE 2024 Rank 1, KMC, Kathmandu",
    image: "https://imgs.search.brave.com/GzRfy2mcClcgeJgwhZf0JYarV2kFByiH4h-FvLo8U-k/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9pbWcu/ZnJlZXBpay5jb20v/cHJlbWl1bS1waG90/by95b3VuZy1mZW1h/bGUtY29sbGVnZS1z/dHVkZW50LWpveWZ1/bGx5LWhvbGRpbmct/Ym9va3MtZmlsZXMt/d2Fsa2luZy1jb25m/aWRlbnRseS1jbGFz/c18xMjE4ODY3LTQ1/MjU4My5qcGc_c2l6/ZT02MjYmZXh0PWpw/Zw"
  }
];

const Testimonials = () => {
  const [visibleCount, setVisibleCount] = useState(3); // Start with 3 testimonials visible

  const handleShowMore = () => {
    setVisibleCount(prevCount => prevCount + 3); // Increase count by 3
  };

  const handleShowLess = () => {
    setVisibleCount(3); // Reset count to 3
  };

  return (
    <div className="testimonials-container">
      <Typography variant="h4" align="center" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' }, 
                        fontWeight: 'bold',
                        color: '#003366',
                        textTransform: 'uppercase', width: '100%' }}>
        Student Voice
      </Typography>
      <Grid container spacing={5} justifyContent="center">
        {testimonialsData.slice(0, visibleCount).map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card className="testimonial-card">
              <CardContent>
                <Avatar src={testimonial.image} alt={testimonial.name} className="testimonial-avatar" />
                <Typography variant="body1" className="testimonial-text">
                  "{testimonial.text}"
                </Typography>
                <Typography variant="h6" className="testimonial-name">
                  {testimonial.name}
                </Typography>
                <Typography variant="subtitle2" className="testimonial-position">
                  {testimonial.position}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {visibleCount < testimonialsData.length ? (
        <Button variant="contained" color="primary" onClick={handleShowMore} sx={{ mt: 2 }}>
          Show More
        </Button>
      ) : (
        <Button variant="outlined" color="secondary" onClick={handleShowLess} sx={{ mt: 2 }}>
          Show Less
        </Button>
      )}
    </div>
  );
};

export default Testimonials;
