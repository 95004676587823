import React from 'react';
import { Box, Typography, Container, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom'; 
import Slider from "react-slick";
import FooterBar from '../../layout/footer_bar/footer_bar_main';
import SecondSection from '../section/Icon_Section';
import OurServices from '../section/services.jsx';
import Testimonial from '../section/Testimonial';
import Message from '../section/message.jsx';
import Information from '../section/newsNotices';
import Videos from '../section/Videos.jsx';

const courses = [
    {
        id: 0,
        title: 'IELTS',
        description: 'The IELTS (International English Language Testing System) is essential for students who wish to study abroad.',
        image: 'https://cdn-hibhh.nitrocdn.com/cSmWTHYMVIExXzmhiWSuqcypazGVPyGD/assets/images/optimized/rev-f0224d7/wcc.ca/wp-content/uploads/2023/04/wcc-blog-How-Long-You-Should-Prepare-For-IELTS.jpg',
        link: '/ielts-course'
    },
    {
        id: 1,
        title: 'PTE',
        description: 'The PTE (Pearson Test of English) is another English language proficiency test for international students.',
        image: 'https://chetanyacareers.com/wp-content/uploads/2022/05/PTE.png',
        link: '/pte-course'
    },
    {
        id: 2,
        title: 'GMAT',
        description: 'The GMAT (Graduate Management Admission Test) is a standardized test for MBA admissions.',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQIpqJPO914oZs-UGk_iidiptUNuc3ch7P3dQ&s',
        link: '/gmat-course'
    },
    {
        id: 3,
        title: 'DSAT',
        description: 'DSAT (Direct Scholastic Aptitude Test) is a college admission test used by various universities.',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0xmtnFyllQUQ7uwTxi2kZrgiojzd9pem_qw&s',
        link: '/dsat-course'
    },
    {
        id: 4,
        title: 'DET',
        description: 'DET (Duolingo English Test) is a popular alternative to IELTS and TOEFL.',
        image: 'https://static.vecteezy.com/system/resources/previews/007/202/394/non_2x/det-letter-logo-design-on-black-background-det-creative-initials-letter-logo-concept-det-letter-design-vector.jpg',
        link: '/det-course'
    }
];

function StudyAbroad() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows:false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <Box sx={{
            backgroundColor: '#f5f5f5',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Container maxWidth="lg"
                // sx={{
                //     backgroundColor: "red"
                // }}
            >
                <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#003366', mt: { xs: 4, md: 6 } }}>
                    Study Abroad Courses
                </Typography>
                <Typography variant="body1" align="center" color="textSecondary" paragraph sx={{ mb: { xs: 4, md: 6 } }}>
                    Explore our comprehensive courses designed to prepare you for your study abroad journey. We offer specialized coaching for various entrance exams and proficiency tests.
                </Typography>

                {/* Slider for Courses */}
                
                <Slider {...settings} 
                    style={{ 
                        backgroundColor: "transparent",
                     }}
                >

                    {courses.map((course) => (
                        <div key={course.id}
                            
                        >
                            <Card sx={{
                                height: '300px', // Reduced height
                                borderRadius: '12px',
                                // boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
                                transition: 'transform 0.2s',
                                '&:hover': {
                                    transform: 'scale(1.05)',
                                },
                                margin: '10px 10px' // Margin to create space between cards
                            }}>
                                <Link to={course.link} style={{ textDecoration: 'none', color: 'inherit', height: '100%' }}>
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <img 
                                            src={course.image} 
                                            alt={course.title} 
                                            style={{ 
                                                width: '100%', 
                                                height: '110px', // Adjusted image height to match the reduced card height
                                                objectFit: 'cover', 
                                                borderRadius: '12px', 
                                                marginBottom: '16px' 
                                            }} 
                                        />
                                        <Typography variant="h6" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                                            {course.title}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" align="center">
                                            {course.description}
                                        </Typography>
                                    </CardContent>
                                </Link>
                            </Card>
                        </div>
                    ))}
                </Slider>
            </Container>

            {/* Other sections */}
            <SecondSection />
            <OurServices />
            <Testimonial />
            <Message />
            <Information />
            <Videos />
            
            <FooterBar />
        </Box>
    );
}

export default StudyAbroad;
