import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import HomePage from './component/pages/homepage'; 
import About from './component/About/About'; 
import Contact from './component/Contact/Contact'; 
import StudyAbroad from './component/AbroadStudy/StudyAbroad'; 
import Navbar from './layout/nav_bar/nav_bar_main'; 
import StudyInNepal from './component/StudyNepal/StudyNepal.jsx';
import BachelorPrograms from './component/StudyNepal/bachelorPrograms.jsx';
import AlumniStudentWithRank from './component/AliminiStudents/AluminiStudentWithRank'; 
import Firstpage from './component/pages/firstpage'; 
import CourseDetail from './component/Courses/CourseDetail';
import KUUMATDetail from './component/Courses/KUUMAT'; 
import CMATDetail from './component/Courses/CMAT';
import BbaFiDetail from './component/Courses/bbafi'; 
import DETCourse from './component/Courses/det';
import IELTSInfo from './component/Courses/ielts';
import PTEInfo from './component/Courses/pte';
import GMATInfo from './component/Courses/gmat';
import DSATInfo from './component/Courses/dsat';

const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const showNavbar = location.pathname !== '/';

    return (
        <>
            {showNavbar && <Navbar />}
            <Routes>
                <Route path="/" element={<Firstpage onNavigate={() => navigate('/home')} />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/study-abroad" element={<StudyAbroad />} /> 
                <Route path="/study-in-nepal" element={<StudyInNepal />} /> 
                <Route path="/ BachelorPrograms" element={<BachelorPrograms/>} />
                <Route path="/cmat" element={<CMATDetail />} />
                <Route path="/bba-fi" element={<BbaFiDetail />} />
                <Route path="/kuumat" element={<KUUMATDetail />} />
                <Route path="/course/:id" element={<CourseDetail />} /> 
                <Route path="/det-course" element={<DETCourse />} />
                <Route path="/ielts-course" element={<IELTSInfo />} />
                <Route path="/pte-course" element={<PTEInfo />} />
                <Route path="/gmat-course" element={<GMATInfo />} />
                <Route path="/dsat-course" element={< DSATInfo/>} />
                {/* <Route path="/det-course" element={<DETCourse />} /> */}

                <Route path="/alumni" element={<AlumniStudentWithRank />} />
               
            </Routes>
        </>
    );
};

const MainApp = () => (
    <Router>
        <App />
    </Router>
);

export default MainApp;
