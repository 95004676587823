import React, { useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../layout/footer_bar/footer_bar_main';

const KUUMATDetail = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    program: 'KUUMAT',
  });

  const kuumatInfo = {
    overview: `The Kirtipur University Management Admission Test (KUUMAT) is an essential entrance exam for aspiring students wishing to pursue management programs at Kirtipur University. The test evaluates candidates' proficiency in various management-related subjects.`,
    eligibility: [
      "Must have completed a minimum of 10+2 or equivalent.",
      "Students with a bachelor's degree can also apply.",
      "Specific eligibility criteria may vary by program.",
    ],
    examPattern: {
      duration: "2 hours",
      totalQuestions: 100,
      markingScheme: "1 mark for each correct answer; 0.25 marks deducted for each wrong answer.",
      sections: [
        "Quantitative Ability",
        "Verbal Ability and Reading Comprehension",
        "Logical Reasoning",
        "General Knowledge",
      ],
    },
    syllabus: [
      {
        title: "Quantitative Ability",
        topics: ["Basic Mathematics", "Statistics", "Graphs", "Data Interpretation"],
      },
      {
        title: "Verbal Ability",
        topics: ["Reading Comprehension", "Vocabulary", "Grammar", "Sentence Correction"],
      },
      {
        title: "Logical Reasoning",
        topics: ["Puzzles", "Analytical Reasoning", "Syllogisms"],
      },
      {
        title: "General Knowledge",
        topics: ["Current Affairs", "History", "Geography", "Economics"],
      },
    ],
    preparationTips: [
      "Regularly review past question papers.",
      "Join study groups for collaborative learning.",
      "Focus on time management during practice tests.",
      "Stay informed about current events.",
    ],
    faqs: [
      {
        question: "What is the KUUMAT exam fee?",
        answer: "The KUUMAT exam fee is NPR 2500, which is non-refundable.",
      },
      {
        question: "How can I apply for the KUUMAT?",
        answer: "Candidates can apply online through the official KUUMAT website.",
      },
      {
        question: "What resources should I use for KUUMAT preparation?",
        answer: "Recommended resources include study guides, online courses, and coaching classes.",
      },
    ],
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted data:', formData);
    // Here you can add your submission logic, e.g., sending data to an API
    handleDialogClose();
  };

  return (
    // <Box sx={{ backgroundColor: '#f5f5f5'}}>
    <Box sx={{marginTop:"3rem"}}>
      <Container maxWidth="lg"  >
        <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#003366'}}>
          KUUMAT (Kirtipur University Management Admission Test)
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          Overview
        </Typography>
        <Typography variant="body1" paragraph>
          {kuumatInfo.overview}
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          Eligibility
        </Typography>
        <ul>
          {kuumatInfo.eligibility.map((item, index) => (
            <li key={index}>
              <Typography variant="body1">{item}</Typography>
            </li>
          ))}
        </ul>

        <Typography variant="h6" component="h2" gutterBottom>
          Exam Pattern
        </Typography>
        <Card sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography variant="body1"><strong>Duration:</strong> {kuumatInfo.examPattern.duration}</Typography>
            <Typography variant="body1"><strong>Total Questions:</strong> {kuumatInfo.examPattern.totalQuestions}</Typography>
            <Typography variant="body1"><strong>Marking Scheme:</strong> {kuumatInfo.examPattern.markingScheme}</Typography>
            <Typography variant="body1"><strong>Sections:</strong> {kuumatInfo.examPattern.sections.join(', ')}</Typography>
          </CardContent>
        </Card>

        <Typography variant="h6" component="h2" gutterBottom>
          Syllabus
        </Typography>
        {kuumatInfo.syllabus.map((subject, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
              <Typography>{subject.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                {subject.topics.map((topic, idx) => (
                  <li key={idx}>
                    <Typography variant="body2">{topic}</Typography>
                  </li>
                ))}
              </ul>
            </AccordionDetails>
          </Accordion>
        ))}

        <Typography variant="h6" component="h2" gutterBottom>
          Preparation Tips
        </Typography>
        <ul>
          {kuumatInfo.preparationTips.map((tip, index) => (
            <li key={index}>
              <Typography variant="body1">{tip}</Typography>
            </li>
          ))}
        </ul>

        <Typography variant="h6" component="h2" gutterBottom>
          Frequently Asked Questions
        </Typography>
        {kuumatInfo.faqs.map((faq, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}b-content`} id={`panel${index}b-header`}>
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}

        <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Button
  variant="contained"
  onClick={handleDialogOpen}
  sx={{
    mr: 2,
    backgroundImage: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',  // Applying the gradient as backgroundImage
    color: 'white',  // Ensure the text is visible over the gradient
    '&:hover': {
      backgroundImage: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)', // Optional: Change gradient on hover
    },
  }}
>
  Apply Now
</Button>

          <Button variant="outlined" color="secondary" href="/contact">
            Contact Us
          </Button>
        </Box>
      </Container>

      {/* Dialog for Apply Now */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Application Form</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Full Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Phone Number"
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Program</InputLabel>
              <Select
                name="program"
                value={formData.program}
                onChange={handleInputChange}
              >
                <MenuItem value="KUUMAT">Kirtipur University Management Admission Test</MenuItem>
                {/* Add more program options if needed */}
              </Select>
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
            Submit Application
          </Button>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <FooterBar />
    </Box>
  );
};

export default KUUMATDetail;
