import React, { useState } from 'react';
import { Box, Typography, Container, Card, CardContent, Button, Accordion, AccordionSummary, AccordionDetails, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../layout/footer_bar/footer_bar_main'; // Adjust the import path as necessary

const DETCourse = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        program: 'Education Course', // Default program
    });

    const courseInfo = {
        overview: `Studying abroad can provide a unique opportunity to experience education in a global context. Education courses abroad offer students the chance to learn different teaching methods, cultural perspectives, and educational philosophies that can enhance their own understanding of education.`,
        eligibility: [
            "Must have completed a minimum of high school education.",
            "Relevant work experience or a bachelor's degree may be preferred.",
            "Some institutions may have specific language requirements."
        ],
        courseDetails: {
            duration: "1 year",
            totalCredits: 30,
            modules: [
                "Introduction to Educational Theories",
                "Cross-Cultural Education",
                "Teaching Methods and Practices",
                "Assessment and Evaluation in Education"
            ],
        },
        preparationTips: [
            "Research various universities and their offerings.",
            "Prepare for language proficiency tests if required.",
            "Gather necessary documents (transcripts, recommendation letters).",
            "Plan your finances and look for scholarships."
        ],
        faqs: [
            {
                question: "What are the costs involved in studying abroad?",
                answer: "Costs vary by country and institution. Typical expenses include tuition fees, accommodation, and living expenses."
            },
            {
                question: "Can I work while studying abroad?",
                answer: "Many countries allow international students to work part-time while studying. Check the local regulations."
            },
            {
                question: "How do I apply for a student visa?",
                answer: "You will need to apply for a student visa after receiving an acceptance letter from your chosen institution."
            }
        ]
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Submitted data:', formData);
        // Add your submission logic here
        handleDialogClose();
    };

    return (
        // <Box sx={{ backgroundColor: '#f5f5f5' }}>
        <Box sx={{marginTop:"5rem"}}>
            <Container maxWidth="lg">
                <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#003366' }}>
                    Study Abroad - Education Courses
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    Overview
                </Typography>
                <Typography variant="body1" paragraph>
                    {courseInfo.overview}
                </Typography>

                <Typography variant="h6" component="h2" gutterBottom>
                    Eligibility
                </Typography>
                <ul>
                    {courseInfo.eligibility.map((item, index) => (
                        <li key={index}>
                            <Typography variant="body1">{item}</Typography>
                        </li>
                    ))}
                </ul>

                <Typography variant="h6" component="h2" gutterBottom>
                    Course Details
                </Typography>
                <Card sx={{ marginBottom: 2 }}>
                    <CardContent>
                        <Typography variant="body1"><strong>Duration:</strong> {courseInfo.courseDetails.duration}</Typography>
                        <Typography variant="body1"><strong>Total Credits:</strong> {courseInfo.courseDetails.totalCredits}</Typography>
                        <Typography variant="body1"><strong>Modules:</strong></Typography>
                        <ul>
                            {courseInfo.courseDetails.modules.map((module, idx) => (
                                <li key={idx}>
                                    <Typography variant="body2">{module}</Typography>
                                </li>
                            ))}
                        </ul>
                    </CardContent>
                </Card>

                <Typography variant="h6" component="h2" gutterBottom>
                    Preparation Tips
                </Typography>
                <ul>
                    {courseInfo.preparationTips.map((tip, index) => (
                        <li key={index}>
                            <Typography variant="body1">{tip}</Typography>
                        </li>
                    ))}
                </ul>

                <Typography variant="h6" component="h2" gutterBottom>
                    Frequently Asked Questions
                </Typography>
                {courseInfo.faqs.map((faq, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}b-content`} id={`panel${index}b-header`}>
                            <Typography>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

                <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Button
  variant="contained"
  onClick={handleDialogOpen}
  sx={{
    mr: 2,
    backgroundImage: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',  // Applying the gradient as backgroundImage
    color: 'white',  // Ensure the text is visible over the gradient
    '&:hover': {
      backgroundImage: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)', // Optional: Change gradient on hover
    },
  }}
>
  Apply Now
</Button>

                    <Button variant="outlined" color="secondary" href="/contact">
                        Contact Us
                    </Button>
                </Box>
            </Container>

            {/* Dialog for Apply Now */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Application Form</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Full Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Email"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Phone Number"
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit} sx={{ mr: 2 }}>
                        Submit Application
                    </Button>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <FooterBar />
        </Box>
    );
};

export default DETCourse;
