import React, { useState } from 'react';
import {
  Box,
  Typography,
  Container,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../layout/footer_bar/footer_bar_main';

const BbaFiDetail = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    program: 'BBA-FI', // Default value
  });

  const bbaFiInfo = {
    overview: `The Bachelor of Finance (BBA-FI) is a four-year undergraduate program designed to provide students with the knowledge and skills needed to succeed in the finance industry. This program covers various aspects of finance, including investment analysis, financial management, and corporate finance.`,
    highlights: [
      "Comprehensive curriculum covering key finance topics.",
      "Experienced faculty with industry backgrounds.",
      "Internship opportunities with leading financial institutions.",
      "Focus on practical learning through case studies and projects.",
      "Access to finance labs and simulation software for hands-on learning.",
      "Networking opportunities with industry professionals through workshops and seminars.",
    ],
    careerOpportunities: [
      "Investment Banking",
      "Financial Analysis",
      "Corporate Finance",
      "Risk Management",
      "Consulting",
      "Asset Management",
      "Insurance Underwriting",
      "Real Estate Finance",
      "Financial Planning and Advisory",
    ],
    courseStructure: [
      {
        title: "Foundations of Business",
        description: "Courses in economics, accounting, business communication, and introductory finance principles. Emphasis on developing analytical and problem-solving skills.",
      },
      {
        title: "Core Finance Subjects",
        description: "Courses in financial management, investment analysis, risk management, and business statistics. Focus on quantitative methods and financial modeling.",
      },
      {
        title: "Advanced Topics",
        description: "Courses in corporate finance, financial markets, portfolio management, and financial derivatives. In-depth analysis of market trends and investment strategies.",
      },
      {
        title: "Capstone Project",
        description: "A comprehensive project integrating all aspects of finance studied, requiring students to develop a full business plan or investment strategy. Collaboration with industry partners for real-world insights.",
      },
    ],
    admissionRequirements: `Applicants should have a high school diploma or equivalent, with a strong background in mathematics and economics preferred. Admission may include standardized test scores, letters of recommendation, and a personal statement.`,
    accreditation: `The BBA-FI program is accredited by relevant educational bodies and recognized by the finance industry, ensuring that the curriculum meets current professional standards and prepares graduates for success in their careers.`,
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted data:', formData);
    // You can add form submission logic here, e.g., sending data to an API
    handleDialogClose();
  };

  return (
    // <Box sx={{ backgroundColor: '#f5f5f5' }}>
    <Box sx={{marginTop:"2rem"}}>
      <Container maxWidth="lg">
        <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#003366' }}>
          Bachelor of Finance (BBA-FI)
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          Overview
        </Typography>
        <Typography variant="body1" paragraph>
          {bbaFiInfo.overview}
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          Program Highlights
        </Typography>
        <ul>
          {bbaFiInfo.highlights.map((highlight, index) => (
            <li key={index}>
              <Typography variant="body1">{highlight}</Typography>
            </li>
          ))}
        </ul>

        <Typography variant="h6" component="h2" gutterBottom>
          Career Opportunities
        </Typography>
        <ul>
          {bbaFiInfo.careerOpportunities.map((career, index) => (
            <li key={index}>
              <Typography variant="body1">{career}</Typography>
            </li>
          ))}
        </ul>

        <Typography variant="h6" component="h2" gutterBottom>
          Course Structure
        </Typography>
        {bbaFiInfo.courseStructure.map((course, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}a-content`} id={`panel${index}a-header`}>
              <Typography>{course.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{course.description}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}

        <Typography variant="h6" component="h2" gutterBottom>
          Admission Requirements
        </Typography>
        <Typography variant="body1" paragraph>
          {bbaFiInfo.admissionRequirements}
        </Typography>

        <Typography variant="h6" component="h2" gutterBottom>
          Accreditation and Recognition
        </Typography>
        <Typography variant="body1" paragraph>
          {bbaFiInfo.accreditation}
        </Typography>

        <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Button
  variant="contained"
  onClick={handleDialogOpen}
  sx={{
    mr: 2,
    backgroundImage: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',  // Applying the gradient as backgroundImage
    color: 'white',  // Ensure the text is visible over the gradient
    '&:hover': {
      backgroundImage: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)', // Optional: Change gradient on hover
    },
  }}
>
  Apply Now
</Button>

          <Button variant="outlined" color="secondary" href="/contact">
            Contact Us
          </Button>
        </Box>
      </Container>

      {/* Dialog for Apply Now */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Application Form</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="Full Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Phone Number"
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Program</InputLabel>
              <Select
                name="program"
                value={formData.program}
                onChange={handleInputChange}
              >
                <MenuItem value="BBA-FI">Bachelor of Finance (BBA-FI)</MenuItem>
                {/* You can add more program options here */}
              </Select>
            </FormControl>
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              Submit Application
            </Button>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <FooterBar />
    </Box>
  );
};
export default BbaFiDetail;