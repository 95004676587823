import React, { useState, useEffect } from 'react';
import { Typography, IconButton, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../assets/educationlogo.png';

const navItems = [
  { name: "Home", to: "/" },
  { name: "Study in Nepal", to: "/study-in-nepal" },
  { name: "Study Abroad", to: "/study-abroad" },
  { name: "About", to: "/about" },
  { name: "Alumni", to: "/alumni" },
  { name: "Contact", to: "/contact" },
];

function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const location = useLocation();

  const toggleDrawer = () => {
    setDrawerOpen(prevState => !prevState);
  };

  // Handle scroll direction
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowNavbar(true); // show navbar on scroll down
      } else {
        setShowNavbar(false);  // hide navbar on scroll up
      }
      setLastScrollY(window.scrollY);
    };
    
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <Box
      component="nav"
      sx={{
        position: 'sticky',
        top: showNavbar ? 0 : '-70px',  // Hide when scrolling down, show when scrolling up
        zIndex: 1000,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px',
        background: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',  // Adds a subtle shadow
        borderRadius: '0 0 15px 15px',  // Rounded bottom corners
        transition: 'top 0.3s', // Smooth slide effect
        animation: showNavbar ? 'slideDown 0.5s ease' : '',
      }}
    >
      <style>
        {`
          @keyframes slideDown {
            from {
              top: -70px;
            }
            to {
              top: 0;
            }
          }
          @keyframes logoScale {
            0%, 100% { transform: scale(1); }
            50% { transform: scale(1.1); }
          }
        `}
      </style>

      <Box 
        component="img" 
        src={logo} 
        alt="Logo" 
        sx={{ 
          height: { xs: '40px', sm: '50px' }, // Responsive logo size
          marginLeft: '3rem', 
          animation: 'logoScale 5s infinite',  // Subtle scaling animation
          cursor: 'pointer',
        }} 
      />

      <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: '30px', marginRight: '4rem' }}>
        {navItems.map((item) => (
          <Typography
            key={item.to}
            sx={{
              fontSize: { xs: '14px', sm: '16px', md: '18px' },  // Responsive font size for navigation items
              color: location.pathname === item.to ? '#E3B81E' : '#ffffff',
              cursor: 'pointer',
              transition: 'color 0.3s, transform 0.3s', // Add smooth color and scale transitions
              '&:hover': { 
                color: '#E3B81E', 
                transform: 'scale(1.1)', // Slightly enlarge on hover
              },
              fontWeight: 'bold',  // Make font bolder
              padding: '5px 10px',
              borderRadius: '5px',
            }}
          >
            <Link to={item.to} style={{ color: 'inherit', textDecoration: 'none' }}>
              {item.name}
            </Link>
          </Typography>
        ))}
      </Box>

      <IconButton
        onClick={toggleDrawer}
        sx={{
          display: { xs: 'block', md: 'none' },
          color: '#fff',
          backgroundColor: '#136CB5',
          '&:hover': { backgroundColor: '#49BBBD' },
          transition: 'background-color 0.3s ease',
          marginRight: '10px',
        }}
      >
        <MenuIcon />
      </IconButton>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <Box
          sx={{
            width: '240px',
            background: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',
            height: '100%',
            color: '#fff',
            padding: '20px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow
          }}
        >
          <IconButton
            onClick={toggleDrawer}
            sx={{ color: '#fff', marginBottom: '20px' }}
          >
            <CloseIcon />
          </IconButton>
          <List>
            {navItems.map((item) => (
              <ListItem
                button
                key={item.to}
                onClick={toggleDrawer}
                sx={{ 
                  color: location.pathname === item.to ? '#E3B81E' : '#fff',
                  '&:hover': { backgroundColor: '#1B87A3' },  // Hover background for drawer items
                }}
              >
                <Link to={item.to} style={{ color: 'inherit', textDecoration: 'none' }}>
                  <ListItemText primary={item.name} />
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}

export default Navbar;
