
// Function to generate alumni data dynamically for a range of years
const generateAlumniData = () => {
    const data = {};

    // Loop through years from 2024 to 2009 and generate alumni data for each year
    for (let year = 2024; year >= 2009; year--) {
        data[year] = [
            { name: `John Doe ${year}`, rank: 1, image: 'https://static.toiimg.com/photo/102391040/102391040.jpg', description: `Graduated with honors in Computer Science, currently working at XYZ Corp,Year ${year}` },
            { name: `Jane Smith ${year}`, rank: 2, image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRro43-XzJH-SnWNXrDU5P8eu4oWNTKDW-AWPcGC7xivWkKPrxibUYYGyZy5RBQkMRDFTY&usqp=CAU', description: `Valedictorian of the class, pursuing a Master’s degree in Data Science,Year ${year}` },
            { name: `Michael Brown ${year}`, rank: 3, image: 'https://www.brookings.edu/wp-content/uploads/2020/09/CUE_Beyond-reopening.jpg?quality=75&w=500', description: `Achieved top rank in Engineering, currently an intern at ABC Inc.,Year ${year}` },
            { name: `Alice Williams ${year}`, rank: 4, image: 'https://www.shutterstock.com/image-photo/student-ready-lessons-classes-teens-260nw-1628181988.jpg', description: `Master’s in Mathematics, now a data analyst,Year ${year}` },
            { name: `David Wilson ${year}`, rank: 5, image: 'https://student-stories.s3.ap-southeast-1.amazonaws.com/student-stories/wp-content/uploads/2016/12/23155440/mythli-mishra-L.jpg', description: `Focused on Environmental Science, working with NGOs,Year ${year}` },
            { name: `Sophia Taylor ${year}`, rank: 6, image: 'https://static.toiimg.com/thumb/msid-64338259,width-400,resizemode-4/64338259.jpg', description: `Known for her leadership skills, currently a project manager,Year ${year}` },
            { name: `James Anderson ${year}`, rank: 7, image: 'https://dpsbareilly.franciscanwebsolutions.com/images/topper/ten/2016-17/Devansh%20Agarwal_CGPA_10.jpg', description: `Graduated in Information Technology, now a software developer,Year ${year}` },
            { name: `Olivia Martinez ${year}`, rank: 8, image: 'https://images.indianexpress.com/2020/08/sudeeksha-bulandshahr.jpg?w=414', description: `Pursuing her PhD in Physics, passionate about research,Year ${year}` },
            { name: `Liam Thomas ${year}`, rank: 9, image: 'https://images.meesho.com/images/products/228180499/gcuy9_512.webp', description: `Entrepreneur in the tech industry, founded a startup,Year ${year}` },
            { name: `Emma Garcia ${year}`, rank: 10, image: 'https://images.meesho.com/images/products/237417468/r0xhw_512.jpg', description: `Top of her class in Law, now a practicing attorney,Year ${year}` },
            { name: `Noah Robinson ${year}`, rank: 11, image: 'https://images.meesho.com/images/products/266283236/0yhzu_512.webp', description: `Graduated in History, working as a museum curator,Year ${year}` },
            { name: `Noah Robinson ${year}`, rank: 12, image: 'https://images.meesho.com/images/products/266283236/0yhzu_512.webp', description: `Graduated in History, working as a museum curator,Year ${year}` },
            { name: `Noah Robinson ${year}`, rank: 13, image: 'https://images.meesho.com/images/products/266283236/0yhzu_512.webp', description: `Graduated in History, working as a museum curator,Year ${year}` },
        ];
    }

    return data;
};

export default generateAlumniData;
