import React from 'react';
import {
  Typography,
  IconButton,
  Tooltip,
  Button,
  Container,
  Box,
} from '@mui/material';
import { Phone, Email } from '@mui/icons-material';
import { keyframes } from '@emotion/react';
import { styled } from '@mui/material/styles';
import logo from '../../assets/educationlogo.png';
import FlagIcon from '@mui/icons-material/Flag';
import GraduationCapIcon from '@mui/icons-material/School';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';

// Define animations
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

const marqueeAnimation = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

// Styled components
const AnimatedText = styled(Typography)(() => ({
  animation: `${pulse}`,
  color: '#fff',
}));

const ScholarshipText = styled(Typography)(({ theme }) => ({
  fontSize: { xs: '1.2rem', sm: '3rem', md: '5rem' },
  fontWeight: 'bold',
  color: '#d32f2f',
  marginTop: '20px',
  [theme.breakpoints.down('xs')]: {
    fontSize: '2rem',
  },
}));

// Marquee Container
const MarqueeContainer = styled(Box)(() => ({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
  boxSizing: 'border-box',
  position: 'relative',
  marginBottom: '-0.5rem', // Reduce gap between logo and marquee container
}));

const MarqueeContent = styled(Box)(() => ({
  display: 'inline-block',
  animation: `${marqueeAnimation} 10s linear infinite`,
}));

// Social media icons container
const IconContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  marginTop: '15px',
}));

// Journey container
const JourneyContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  maxWidth: '1200px',
  marginBottom: '5rem',
  flexDirection: 'row', 
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
    display: 'none', 
  },
}));

// Icon styles for responsiveness (Hide on small screens)
const IconStyle = styled(IconButton)(({ theme }) => ({
  fontSize: '3rem',  
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',  
    display: 'none',  
  },
}));

const PlaneIcon = styled(AirplanemodeActiveIcon)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  animation: 'fly 3s linear infinite',
  '@keyframes fly': {
    '0%': { transform: 'translate(-50%, -50%) translateX(-100%)' },
    '100%': { transform: 'translate(-50%, -50%) translateX(100%)' },
  },
});

// Button container
const CenteredButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '2rem',  
  gap: '5rem',
  flexDirection: { xs: 'column', sm: 'row' }, // Stack buttons on small screens
}));

const Firstpage = () => {
  return (
    <Container
      sx={{
        height: '100vh', 
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Box sx={{ width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {/* Static logo */}
        <Box component="span" sx={{ display: 'inline-flex', alignItems: 'center', marginBottom: '1rem', width: { xs: '100px', sm: '120px', md: '150px', lg: '180px' } }}>
          <img src={logo} alt="Logo" style={{ width: '100%', height: 'auto' }} />
        </Box>

        {/* Marquee effect for Phone and Email */}
        <MarqueeContainer sx={{ marginLeft: '2rem' }}>
          <MarqueeContent>
            <Tooltip title="Phone Number">
              <IconButton color="inherit">
                <Phone />
                <AnimatedText variant="body1" sx={{ ml: 1, color: 'black', fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem' } }}>
                  01-4168086, 9841354464 &nbsp; | &nbsp;
                </AnimatedText>
              </IconButton>
            </Tooltip>
            <Tooltip title="Email">
              <IconButton color="inherit">
                <Email />
                <AnimatedText variant="body1" sx={{ ml: 1, color: 'black', fontSize: { xs: '0.8rem', sm: '1rem', md: '1rem' } }}>
                  education.com4@gmail.com
                </AnimatedText>
              </IconButton>
            </Tooltip>
          </MarqueeContent>
        </MarqueeContainer>
      </Box>

      <Box sx={{ width: '100%', height: '70vh', textAlign: 'center', marginBottom: '-2rem' }}>
        <JourneyContainer>
          <Tooltip title="Nepal Flag">
            <IconStyle>
              <FlagIcon fontSize="inherit" sx={{ color: '#c0392b', marginRight: '15rem' }} />
            </IconStyle>
          </Tooltip>
          <PlaneIcon />
          <Tooltip title="Graduation">
            <IconStyle>
              <GraduationCapIcon fontSize="inherit" sx={{ color: '#1976d2', marginLeft: '15rem' }} />
            </IconStyle>
          </Tooltip>
        </JourneyContainer>

        <Typography variant="h3" gutterBottom sx={{ marginBottom: "3rem", fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' } }}>
          Welcome to Education.com
        </Typography>
        <ScholarshipText sx={{ marginTop: "-1.5rem", fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' } }}>
          Study Abroad with 100% Scholarship!
        </ScholarshipText>

        {/* Social Media Icons */}
        <IconContainer sx={{ marginBottom: '2rem' }}>
          <Tooltip title="Twitter" target="_blank">
            <IconButton>
              <TwitterIcon sx={{ color: '#1DA1F2' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Facebook">
            <IconButton>
              <FacebookIcon sx={{ color: '#3b5998' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Instagram" target="_blank">
            <IconButton sx={{ fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } }}>
              <InstagramIcon sx={{ color: '#C13584' }} />
            </IconButton>
          </Tooltip>
        </IconContainer>

        {/* Buttons for navigation */}
        <Box sx={{ marginBottom: '2rem' }}>
          <CenteredButtonContainer>
            <Link to="/study-in-nepal" style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  padding: '1rem 2rem',
                  fontSize: { xs: '0.9rem', sm: '1.2rem', md: '1.5rem' },
                  boxShadow: 3,
                }}
              >
                Study Nepal
              </Button>
            </Link>
            <Link to="/study-abroad" style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                size="large"
                sx={{
                  padding: '1rem 2rem',
                  fontSize: { xs: '0.9rem', sm: '1.2rem', md: '1.5rem' },
                  boxShadow: 3,
                }}
              >
                Study Abroad
              </Button>
            </Link>
          </CenteredButtonContainer>
        </Box>
      </Box>
    </Container>
  );
};

export default Firstpage;
