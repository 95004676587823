import React from 'react';
import { Box, Typography, Container, Card, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid2';

const programsData = [
    {
        programTitle: 'Tribhuvan University (TU)',
        programs: ['BBA', 'BIM', 'BHM', 'BTTM', 'BBM', 'BCA', 'BIT', 'BMS', 'BPA', 'BSC.CSIT']
    },
    {
        programTitle: 'Kathmandu University (KU)',
        programs: ['BBA', 'BBIS', 'BHM', 'BPH']
    },
    {
        programTitle: 'Pokhara University (PoU)',
        programs: ['BBA', 'BBA-BI', 'BBA-TT', 'BCIS', 'BHM', 'BHCM']
    },
    {
        programTitle: 'Purbanchal University (PuU)',
        programs: ['BBA', 'BHM', 'BIT', 'BHCM', 'BCS', 'BTTS']
    }
];

const BachelorPrograms = () => {
    return (
        <Box sx={{ py: 4 }}>
            <Container maxWidth="lg">
                <Typography
                    variant="h4"
                    align="center"
                    sx={{
                        fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                        fontWeight: 'bold',
                        color: '#003366',
                        textTransform: 'uppercase',
                        mb: 4,
                    }}
                >
                    Bachelor's Programs in Nepal
                </Typography>

                <Grid container spacing={4} justifyContent="center">
                    {programsData.map((university, index) => (
                        <Grid
                            item
                            xs={12} sm={6} md={3}  // Ensures 4 cards per row on 'md' and larger screens
                            key={index}
                        >
                            <Card
                                sx={{
                                    color: '#333',
                                    borderRadius: '12px',
                                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        boxShadow: '0 6px 15px rgba(0, 0, 0, 0.15)',
                                    },
                                    transition: 'transform 0.3s ease',
                                    minWidth: '200px',
                                }}
                            >
                                <CardContent sx={{ textAlign: 'center' }}>
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: '600',
                                            mb: 2,
                                            fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem' },
                                        }}
                                    >
                                        {university.programTitle}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            mb: 2,
                                            fontSize: { xs: '0.875rem', sm: '1rem', md: '1.1rem' },
                                        }}
                                    >
                                        Programs:
                                    </Typography>
                                    <Box component="ul" sx={{ padding: 0, listStyleType: 'none', textAlign: 'left' }}>
                                        {university.programs.map((program, programIndex) => (
                                            <Typography
                                                component="li"
                                                key={programIndex}
                                                variant="body2"
                                                sx={{
                                                    marginBottom: '4px',
                                                    fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' },
                                                }}
                                            >
                                                - {program}
                                            </Typography>
                                        ))}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default BachelorPrograms;
