
import React, { useState } from 'react'; 
import { Box, Typography, Grid, Card, CardContent, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'; // Import Dialog components from MUI
import { School, People, Event, Book, Work } from '@mui/icons-material';

const services = [
  {
    id: 1,
    title: "Online Courses",
    description: "Access a variety of online courses tailored to your learning needs.",
    icon: <School style={{ fontSize: 40 }} />,
    details: "Detailed description of Online Courses service goes here.",
  },
  {
    id: 2,
    title: "Personalized Tutoring",
    description: "One-on-one tutoring sessions to enhance your understanding of subjects.",
    icon: <People style={{ fontSize: 40 }} />,
    details: "Detailed description of Personalized Tutoring service goes here.",
  },
  {
    id: 3,
    title: "Workshops and Seminars",
    description: "Engage in hands-on workshops and informative seminars.",
    icon: <Event style={{ fontSize: 40 }} />,
    details: "Detailed description of Workshops and Seminars service goes here.",
  },
  {
    id: 4,
    title: "Educational Resources",
    description: "Access a range of resources including books, articles, and videos.",
    icon: <Book style={{ fontSize: 40 }} />,
    details: "Detailed description of Educational Resources service goes here.",
  },
  {
    id: 5,
    title: "Career Counseling",
    description: "Receive guidance and support for your career path and goals.",
    icon: <Work style={{ fontSize: 40 }} />,
    details: "Detailed description of Career Counseling service goes here.",
  },
  {
    id: 6,
    title: "Study Groups",
    description: "Join study groups to collaborate and learn with peers.",
    icon: <People style={{ fontSize: 40 }} />,
    details: "Detailed description of Study Groups service goes here.",
  },
];

function OurServices() {
   const [selectedService, setSelectedService] = useState(null); // Initialize state for selected service

   const handleDialogOpen = (service) => {
     setSelectedService(service); // Set selected service when Learn More button is clicked
   };

   const handleDialogClose = () => {
     setSelectedService(null); // Close the dialog
   };
   
  return (
    <Box sx={{ padding: 4, backgroundColor: '#eaf2f8'  }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
          fontWeight: 'bold',
          color: '#003366',
          textTransform: 'uppercase',
          marginBottom:'2.2rem'
        }}
      >
        Our Educational Services
      </Typography>

      {/* Grid Layout for Services */}
      <Grid container spacing={3} justifyContent="center">
        {services.map((service) => (
          <Grid item xs={12} sm={6} md={4} key={service.id}>
            <Card
              variant="outlined"
              sx={{
                textAlign: 'center',
                boxShadow: 3,
                transition: 'transform 0.3s',
                width: '100%',
                maxWidth: 300,
                margin: '0 auto',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: 6,
                },
              }}
            >
              <CardContent sx={{ padding: 2 }}>
                {service.icon}
                <Typography variant="h6" component="div" sx={{ marginTop: 1, color: '#003366' }}>
                  {service.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 1 }}>
                  {service.description}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#ff9800',
                    '&:hover': { backgroundColor: '#ff5722' },
                    color: 'white',
                  }}
                  onClick={() => handleDialogOpen(service)} // Open dialog when clicked
                >
                  Learn More
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog for showing service details */}
      {selectedService && (
        <Dialog open={Boolean(selectedService)} onClose={handleDialogClose}>
          <DialogTitle sx={{ color: '#003366', fontSize: { xs: '1.2rem', sm: '1.6rem' } }}>
            {selectedService.title}
          </DialogTitle>
          <DialogContent>
            <Typography sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>
              {selectedService.details}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleDialogClose} 
              variant="contained" 
              sx={{
                backgroundColor: '#ff9800',
                '&:hover': { backgroundColor: '#ff5722' },
                color: 'white',
              }}
            >
              Learn Less
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

export default OurServices;
