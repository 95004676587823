import React, { useState } from 'react';
import {
    Box,
    Typography,
    Container,
    Grid,
    Card,
    CardContent,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FooterBar from '../../layout/footer_bar/footer_bar_main';

const PTEInfo = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: ''
    });

    const courseInfo = {
        overview: `The PTE (Pearson Test of English) is a computer-based English language test that is designed to assess the speaking, writing, reading, and listening skills of non-native English speakers. It is widely accepted by universities, colleges, and governments around the world.`,
        importance: `PTE is crucial for students seeking admission in English-speaking countries. Its results are recognized globally, ensuring that students have the necessary English language proficiency for academic success.`,
        testFormat: [
            { title: "Speaking & Writing", details: "77-93 minutes, includes tasks like Read Aloud, Repeat Sentence, and Essay writing." },
            { title: "Reading", details: "32-41 minutes, with multiple-choice, re-order paragraphs, and fill in the blanks tasks." },
            { title: "Listening", details: "45-57 minutes, includes various audio formats and question types." }
        ],
        preparationTips: [
            "Take practice tests available on the official PTE website.",
            "Familiarize yourself with the test format.",
            "Improve your English skills daily through speaking and writing.",
            "Consider joining preparation courses for guided study."
        ],
        faqs: [
            {
                question: "How long are PTE scores valid?",
                answer: "PTE scores are valid for two years from the test date."
            },
            {
                question: "What is a good score on the PTE?",
                answer: "Generally, a score of 65 or above is considered competitive for university admissions."
            },
            {
                question: "Can I take the PTE test online?",
                answer: "Yes, PTE offers an online version of the test in certain regions."
            }
        ]
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Submitted data:', formData);
        // Add your submission logic here
        handleDialogClose();
    };

    return (
        <Box sx={{ backgroundColor: '#f5f5f5'}}>
            <Container maxWidth="lg">
                <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#003366' }}>
                    PTE (Pearson Test of English)
                </Typography>

                <Typography variant="body1" paragraph>
                    {courseInfo.overview}
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Importance of PTE
                </Typography>
                <Typography variant="body1" paragraph>
                    {courseInfo.importance}
                </Typography>

                <Typography variant="h5" component="h2" gutterBottom>
                    Test Format
                </Typography>
                <Grid container spacing={2}>
                    {courseInfo.testFormat.map((format, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card sx={{ height: '100%' }}>
                                <CardContent>
                                    <Typography variant="h6">{format.title}</Typography>
                                    <Typography variant="body2">{format.details}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
                    Preparation Tips
                </Typography>
                <ul>
                    {courseInfo.preparationTips.map((tip, index) => (
                        <li key={index}>
                            <Typography variant="body1">{tip}</Typography>
                        </li>
                    ))}
                </ul>

                <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
                    Frequently Asked Questions
                </Typography>
                {courseInfo.faqs.map((faq, index) => (
                    <Accordion key={index}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} id={`panel${index}-header`}>
                            <Typography>{faq.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>{faq.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}

                <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Button
  variant="contained"
  onClick={handleDialogOpen}
  sx={{
    mr: 2,
    backgroundImage: 'linear-gradient(90deg, #49BBBD 0%, #136CB5 100%)',  // Applying the gradient as backgroundImage
    color: 'white',  // Ensure the text is visible over the gradient
    '&:hover': {
      backgroundImage: 'linear-gradient(90deg, #136CB5 0%, #49BBBD 100%)', // Optional: Change gradient on hover
    },
  }}
>
  Apply Now
</Button>

                    <Button variant="outlined" color="secondary" href="/contact">
                        Contact Us
                    </Button>
                </Box>
            </Container>

            {/* Dialog for Apply Now */}
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Application Form</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Full Name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Email"
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Phone Number"
                            type="tel"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            required
                        />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>
                        Submit Application
                    </Button>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <FooterBar/>
        </Box>
    );
};

export default PTEInfo;
