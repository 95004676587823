
import React, { useState } from "react";
import { Card, Box, IconButton, Modal, Backdrop, Fade, Button, Typography } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const videos = [
  { videoId: "zojh-_mJP2E", alt: "Education Video 1", image: "https://img.youtube.com/vi/zojh-_mJP2E/hqdefault.jpg" },
  { videoId: "RCj5wfAQWok", alt: "Video 2", image: "https://img.youtube.com/vi/RCj5wfAQWok/hqdefault.jpg" },
  { videoId: "ejUHWQfBOrc", alt: "Video 3", image: "https://img.youtube.com/vi/ejUHWQfBOrc/hqdefault.jpg" },
  { videoId: "9CXZW-7I65I", alt: "Video 4", image: "https://img.youtube.com/vi/9CXZW-7I65I/hqdefault.jpg" },
];

function Carousel() {
  const [videoOrder, setVideoOrder] = useState(videos);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [selectedTab, setSelectedTab] = useState("image"); // Default selected tab is "image"

  const handleNext = () => {
    setVideoOrder((prevOrder) => {
      const newOrder = [...prevOrder];
      const firstVideo = newOrder.shift(); // Remove the first video
      newOrder.push(firstVideo); // Add it to the end
      return newOrder; // Update the order
    });
  };

  const handlePrev = () => {
    setVideoOrder((prevOrder) => {
      const newOrder = [...prevOrder];
      const lastVideo = newOrder.pop(); // Remove the last video
      newOrder.unshift(lastVideo); // Add it to the start
      return newOrder; // Update the order
    });
  };

  // const handleImageClick = (image) => {
  //   setModalContent({ type: "image", content: image });
  //   setOpen(true);
  //   setSelectedTab("image"); // Set the selected tab to image
  // };

  const handleVideoClick = (videoId) => {
    setModalContent({ type: "video", content: videoId });
    setOpen(true);
    setSelectedTab("video"); // Set the selected tab to video
  };

  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ textAlign: "center", mt: 2 }}>
      {/* Buttons for Image and Video Selection */}
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          color={selectedTab === "image" ? "success" : "primary"} // Green for selected tab
          onClick={() => setSelectedTab("image")}
          sx={{ mr: 2 }}
        >
          Image
        </Button>
        <Button
          variant="contained"
          color={selectedTab === "video" ? "success" : "primary"} // Green for selected tab
          onClick={() => setSelectedTab("video")}
        >
          Video
        </Button>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 2 }}>
        <IconButton onClick={handlePrev} size="large">
          <ArrowBackIosIcon />
        </IconButton>

        <Box sx={{ overflow: "hidden", width: { xs: "100%", sm: "80%" }, position: "relative" }}>
          <Box
            sx={{
              display: "flex",
              transition: "transform 0.5s ease",
              transform: `translateX(-${(videoOrder.indexOf(videoOrder[0]) * (100 / 4))}%)`,
            }}
          >
            {videoOrder.slice(0, 4).map((video, i) => (
              <Box
                key={i}
                sx={{
                  flex: `0 0 25%`, // Show 4 items at a time
                  padding: { xs: '0.5rem', sm: '1rem' }, // Padding for responsiveness
                }}
              >
                <Card
                  sx={{
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                    borderRadius: "10px",
                    overflow: "hidden",
                    height: { xs: 200, md: 250 },
                    width: "100%", // Make sure the card fills the box
                  }}
                >
                  {selectedTab === "image" ? (
                    // Render the image
                    <img
                      src={video.image}
                      alt={video.alt}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        cursor: "default", // Non-clickable when Image tab is selected
                      }}
                    />
                  ) : selectedTab === "video" ? (
                    // Render the video button
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        cursor: "pointer",
                        flexDirection: "column",
                      }}
                      onClick={() => handleVideoClick(video.videoId)} // Open video in modal
                    >
                      <Typography variant="body2" sx={{ textAlign: "center", padding: "10px" }}>
                        Watch Video
                      </Typography>
                      <img
                        src={video.image}
                        alt={video.alt}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  ) : null}
                </Card>
              </Box>
            ))}
          </Box>
        </Box>

        <IconButton onClick={handleNext} size="large">
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>

      {/* Modal for showing image or video */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'white',
            borderRadius: 2,
            boxShadow: 24,
            // p: 4,
            maxWidth: '70%',
            maxHeight: '80%',
            overflow: 'auto',
          }}>
            {modalContent?.type === "image" ? (
              <img
                src={modalContent.content}
                alt="Modal Content"
                style={{
                  width: '100%',
                  height: 'auto',
                  objectFit: 'contain',
                }}
              />
            ) : modalContent?.type === "video" ? (
              <iframe
                width="100%"
                height="400"
                src={`https://www.youtube.com/embed/${modalContent.content}`}
                title="Video"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            ) : null}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default Carousel;
