import React from 'react';
import SecondSection from '../section/Icon_Section.jsx';
import Footerbar from '../../layout/footer_bar/footer_bar_main.jsx';
import OurServices from '../section/services.jsx';
import Testimonial from '../section/Testimonial.jsx';
import Message from '../section/message.jsx';
import Information from '../section/newsNotices.jsx';
import Carousel from '../section/Videos.jsx';

const HomePage = () => {
  return (
    <>
      
      <SecondSection />
      <OurServices />
      <Testimonial />
      <Message />
      <Information />
      <Carousel />
      <Footerbar />
    </>
  );
};

export default HomePage;
