import React from 'react';
import { Box, Typography, TextField, Button, Container, Grid, Paper } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FooterBar from '../../layout/footer_bar/footer_bar_main';

function Contact() {
    const handleSubmit = (e) => {
        e.preventDefault();
        alert('Message submitted!');
    };

    return (
        <Box>
            <Container maxWidth="lg" sx={{ my: 5 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#003366' }}>
                    Contact Us
                </Typography>
                <Paper elevation={3} sx={{ p: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            label="Name"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            type="email"
                                            label="Email"
                                            fullWidth
                                            variant="outlined"
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            label="Message"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            sx={{ mb: 2 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button type="submit" variant="contained" color="primary" >
                                            Send Message
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>

                        {/* Map Section */}
                        <Grid item xs={12} md={6}>
                            
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.677634116068!2d85.324!3d27.7172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb191c29b0b3df%3A0xe295c0a12b3c5820!2sKathmandu%2C%20Nepal!5e0!3m2!1sen!2sus!4v1617306220567!5m2!1sen!2sus"
                                width="100%"
                                height="300"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                title="Google Map of Kathmandu, Nepal"
                            ></iframe>
                        </Grid>
                    </Grid>
                </Paper>

                {/* Additional sections for contact info and social media */}
                <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', mt: 5, color: '#003366' }}>
                    Get in Touch
                </Typography>
                <Typography variant="body1" align="center" color="textSecondary">
                    Email: support@education.com
                </Typography>
                <Typography variant="body1" align="center" color="textSecondary">
                    Phone: +977 9812345786
                </Typography>

                {/* Social Media Section */}
                <Box>
                    <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: '#003366' }}>
                        Follow Us
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                            <FacebookIcon fontSize="large" sx={{ color: '#4267B2' }} />
                        </a>
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                            <TwitterIcon fontSize="large" sx={{ color: '#1DA1F2' }} />
                        </a>
                        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                            <LinkedInIcon fontSize="large" sx={{ color: '#0077B5' }} />
                        </a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                            <InstagramIcon fontSize="large" sx={{ color: '#C13584' }} />
                        </a>
                    </Box>
                </Box>
            </Container>

            <FooterBar />
        </Box>
    );
}

export default Contact;
