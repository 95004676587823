import React, { useState } from "react";
import { Box, Typography, Avatar, Card, CardContent, Button, Grid } from '@mui/material';
import chairpersonImage from '../../assets/chairperson.jpg'; // Adjust the path accordingly

function ChairpersonMessage() {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleMessage = () => {
        setIsExpanded(prev => !prev);
    };
    return (
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                marginTop: { xs: 2, md: 4 }, 
                padding: { xs: '1rem', sm: '2rem' }, // Adjust padding for responsiveness
            }}
        >
            <Typography 
                variant="h4" 
                align="center" 
                gutterBottom 
                sx={{ 
                    fontWeight: 'bold', 
                    color: '#003366',
                    fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' } // Adjust font size for different screens
                }}
            >
                Message From Chairman    
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                {/* Message Card */}
                <Grid item xs={12} sm={10} md={6} lg={4}>
                    <Card sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: 'auto', // Allow card height to be flexible
                        backgroundColor: '#f9f9f9', 
                        borderRadius: 2, 
                        boxShadow: 3,
                        padding: 2,
                        transition: 'transform 0.2s',
                        '&:hover': {
                            transform: 'scale(1.02)',
                            boxShadow: 6
                        }
                    }}>
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography 
                                variant="body1" 
                                sx={{ 
                                    color: '#555', 
                                    lineHeight: 1.6, 
                                    fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } // Adjust font size for responsiveness
                                }}
                            >
                                <br /><br />
                                Welcome to Education.com! As the chairperson, I am honored to guide this community of learners and educators. 
                                Our goal is to empower every individual with the tools they need to succeed.
                                {isExpanded && (
                                    <>
                                        <br /><br />
                                        We are committed to fostering excellence and inclusivity. Thank you for being part of our journey!
                                    </>
                                )}
                                <br /><br />
                                Best regards,
                                <br />
                                [Anil Bhandari]
                            </Typography>
                        </CardContent>
                        {/* Read More Button  */}
                        <Button 
                            variant="contained" 
                            sx={{ 
                                alignSelf: 'flex-end', // Align button to the right
                                marginTop: 3,
                                backgroundColor: '#ff9800',
                                '&:hover': { backgroundColor: '#ff5722' },
                                color: 'white',
                                fontSize: { xs: '0.8rem', sm: '0.9rem' } // Responsive button font size
                            }} 
                            onClick={toggleMessage}
                        >
                            {isExpanded ? "Read Less" : "Read More"}
                        </Button>
                    </Card>
                </Grid>

                {/* Image Card */}
                <Grid item xs={12} sm={10} md={6} lg={4} sx={{ display: { xs: 'none', md: 'block' } }}>
                    <Card sx={{ 
                        height: '100%', // Flexible height for responsiveness
                        borderRadius: 2, 
                        boxShadow: 3,
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center',
                        transition: 'transform 0.2s',
                        '&:hover': {
                            transform: 'scale(1.02)',
                            boxShadow: 6
                        }
                    }}>
                        <Avatar 
                            alt="Chairperson" 
                            src={chairpersonImage} 
                            sx={{ 
                                width: '100%',  
                                height: '100%', 
                                borderRadius: 2, 
                                objectFit: 'cover', 
                            }} 
                        />
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ChairpersonMessage;
